export function updateActivity(e) {
  e.preventDefault();
  const selectedActivity = e.currentTarget.textContent;
  const desktopNav = document.querySelector(".desktop-navigation");
  const mobileNav = document.querySelector(".mobile-navigation");
  const activityListDesktop = desktopNav.querySelector("#activity-list-parent");
  const activityListMobile = mobileNav.querySelector("#activity-list-parent");
  const activitiesDesktop = activityListDesktop.querySelectorAll(
    ".presentation .tab-link"
  );
  const activitiesMobile = activityListMobile.querySelectorAll(
    ".presentation .tab-link"
  );
  const boatFamilyContainer = document.getElementById("boat-family-container");
  const boats = boatFamilyContainer.querySelectorAll("[data-activitylist]");
  const boatsArray = Array.from(boats);
  const boatsSelected = boatsArray.filter((boat) => {
    const activityList = boat.getAttribute("data-activitylist").split(",");
    return activityList.includes(selectedActivity);
  });
  const boatFamily = boatFamilyContainer.querySelectorAll("[data-boatfamily]");
  activitiesDesktop.forEach((element) => element.classList.remove("active"));
  activitiesMobile.forEach((element) => element.classList.remove("active"));
  const index = Array.from(activitiesDesktop).findIndex(
    (activity) =>
      activity.textContent.toLowerCase() === selectedActivity.toLowerCase()
  );
  if (index !== -1) {
    activitiesDesktop[index].classList.add("active");
    activitiesMobile[index].classList.add("active");
  }
  boats.forEach((e) => e.classList.add("hidden"));
  boatsSelected.forEach((e) => e.classList.remove("hidden"));
  if (selectedActivity === "All Models") {
    boats.forEach((e) => e.classList.remove("hidden"));
  }
  boatFamily.forEach((item) => {
    const children = Array.from(item.children);
    const specificBoatFamily = boatFamilyContainer.querySelector(
      `[data-boatfamily="${item.dataset.boatfamily}"]`
    );
    specificBoatFamily.parentElement.classList.toggle(
      "hidden",
      children.every((child) => child.classList.contains("hidden"))
    );
    var boatsVisible = children.filter(
      (child) => !child.classList.contains("hidden")
    );
    var boatsVisibleCount = boatsVisible.length;
    if (boatsVisibleCount === 1) {
      /*console.log("one boat");*/
      specificBoatFamily.setAttribute("class", "px-[var(--container-padding-lg)] pb-[var(--stacked-component-lg)] w-full");
      boatsVisible[0].setAttribute(
        "class",
        "justify-between md:items-start items-center flex flex-col lg:flex-row w-full lg:gap-8 lg:items-center lg:justify-center md:justify-center md:items-center"
      );
      boatsVisible[0]
        .querySelector("img")
        .setAttribute("class", "w-[327px] h-[184px] md:w-[750px] md:h-[422px]");
    } else if (boatsVisibleCount > 1) {
     /* console.log("more than one boat");*/
      specificBoatFamily.setAttribute(
        "class",
        "px-[var(--container-padding-lg)] pb-[var(--stacked-component-lg)] w-full grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 justify-between lg:gap-14 md:gap-y-12 gap-10"
      );
      boatsVisible.forEach((element) => {
        element.setAttribute(
          "class",
          "justify-between items-center flex flex-col"
        );
        element
          .querySelector("img")
          .setAttribute("class", "w-[327px] h-[184px]");
      });
    }
  });
}

export function navigateTo(e) {
  e.preventDefault();
  let navigationUrl = e.currentTarget.dataset.navigationurl;
  window.location.pathname = navigationUrl;
}

export function onChickletClick(e) {
  e.stopPropagation();
}

export function updateAthleteList(e) {
  e.preventDefault();
  const selectedActivity = e.currentTarget.textContent;
  const desktopNav = document.querySelector(".js--desktop-navigation-athlete");
  // const mobileNav = document.querySelector('.mobile-navigation-athlete');
  const activityListDesktop = desktopNav.querySelector(
    "#activity-list-athlete-parent"
  );
  // const activityListMobile = mobileNav.querySelector('#activity-list-athlete-parent');
  const activitiesDesktop = activityListDesktop.querySelectorAll(
    ".presentation .tab-link"
  );
  // const activitiesMobile = activityListMobile.querySelectorAll(".presentation .tab-link");
  const athletesContainer = document.getElementById("athlete-container");
  const athletes = athletesContainer.querySelectorAll("[data-activitytype]");
  const athletesSelected = athletesContainer.querySelectorAll(
    `[data-activitytype="${selectedActivity}"]`
  );
  // Update selected activity appearance
  activitiesDesktop.forEach((element) => element.classList.remove("active"));
  // activitiesMobile.forEach(element => element.classList.remove('active'));
  const index = Array.from(activitiesDesktop).findIndex(
    (activity) =>
      activity.textContent.toLowerCase() === selectedActivity.toLowerCase()
  );
  if (index !== -1) {
    activitiesDesktop[index].classList.add("active");
    // activitiesMobile[index].classList.add('active');
  }
  // Hide all athletes and show selected ones
  athletes.forEach((e) => e.classList.add("hidden"));
  athletesSelected.forEach((e) => e.classList.remove("hidden"));
  if (selectedActivity === "All Athletes") {
    athletes.forEach((e) => e.classList.remove("hidden"));
  }
}

//  export function updateEventLink(e){
//   console.log(e.currentTarget === this);
//   const tabs = document.getElementById('eventPg__tabWrapper').querySelectorAll('.js--tab-link');
//   tabs.forEach(tab => {
//     tab.classList.remove("active");
//   });
//   e.currentTarget.classList.add("active");
//  }
