let elem = document.querySelectorAll(
    '[data-alias-name="featureAccordionContainerRow"]'
);
featureAccordion(elem);

document.addEventListener("load", function () {
    elem = document.querySelectorAll(
        '[data-alias-name="featureAccordionContainerRow"]'
    );
    featureAccordion(elem);
});

function featureAccordion(elem) {
    if (elem == null) return;
    elem.forEach(item => {
        const { aliasName, uniqueId } = item.dataset;
        const accordionHeaderElem = item.querySelectorAll(`#${aliasName}-${uniqueId}-accordion > h2[id] > button`);
        const desImages = item.querySelectorAll('.js--imageWrapper > img');
        let activeIndex = 0;
        accordionHeaderElem.forEach(item => {
            item.addEventListener('click', () => {
                activeIndex = parseInt(item.dataset.index);
                desImages.forEach(item => item.ariaHidden = true);
                desImages[activeIndex].ariaHidden = false;
            })
        })
        accordionHeaderElem[0].click();
    });

}
